<template>
  <div class="auth-page">
    <div>
      <p style="font-size: 18px; text-align: center;">{{
          $t("autorizationPage.autorization")
        }}</p>
    </div>
    <van-col class="itserve-center mb-15" span="24">
      <itserve-button
          :value="$t('buttons.google')"
          type="primary"
          @click="googleSign"
          class="mt-15 apply-loader"
          :icon="true"
      >
        <img
            src="@/assets/images/icons8-google-48.png"
            width="30px"
            style="margin-left: 75px"
        />
      </itserve-button>
    </van-col>
    <template v-if="nativeData.platform === 'ios'">
      <van-col class="itserve-center mb-15" span="24">
        <itserve-button
            :value="$t('buttons.apple')"
            type="primary"
            @click="appleSign"
            class="mt-15 apply-loader"
            :icon="true"
        >
          <img
              src="@/assets/images/apple-logo-svgrepo-com.svg"
              width="30px"
              style="margin-left: 60px"
          />
        </itserve-button>
      </van-col>
    </template>
    <van-col class="itserve-center mb-15" span="24">
      <p>
        <label
            style="color: #cccccc; text-decoration: underline ; font-size: 24px"
            @click="guest"
        >{{ $t('buttons.skip') }}</label>
      </p>
    </van-col>
  </div>
</template>
<script>
import "@/plugins/nativescript-webview-interface.js";
import ItserveButton from "@/components/forms/ItserveButton";
// import InfoMessage from "@/components/content/InfoMessage";
export default {
  name: "AutorizationPage",
  created() {},
  computed: {
    nativeData() {
      return this.$store.state.Auth.nativeData;
    },
  },
  methods: {
    guest() {
      this.$store.commit("Auth/SET_GUEST", true);
      this.$store.dispatch("Auth/storeGuest");
      this.$router.push({
        name: "Home",
      });
    },
    googleSign() {
      this.nativeSendEvent("googleSignin");
      //this.$store.state.Auth.nativeData = null;
    },

    appleSign() {
      this.nativeSendEvent("appleSignin");
    },
  },
  components: {
    // InfoMessage,
    ItserveButton,
  },
};
</script>

<style scoped>
.auth-page {
  background-color: #1d1d1d;
  height: 100vh;
}
</style>
